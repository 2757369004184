import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  introSection,
  // description,
  row,
  // col6,
  col12,
  // marginTopSubstitute,
} from "./subpage.module.scss";

const ClinicalDataRepositoryPage = () => {
  return (
    <Layout pageTitle="Clinical Data Repository" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                {/* <p className={description}></p> */}
                <h1>Clinical Data Repository</h1>
                <p className="text-medium">
                  Semantisches Datenmodell – Das Objektmodell bildet alle Daten
                  ab, strukturierte und unstrukturierte. Es lässt sich ergänzen
                  ohne Programmierung, ohne Betriebsunterbruch. Auf diese Weise
                  kann zukünftigen Bedürfnissen jederzeit entsprochen werden.
                </p>
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                <h3>HTML 5 User Interface</h3>
                <ul>
                  <li>
                    Intuitive Oberfläche, an den Paradigmen von Apps orientiert
                  </li>
                  <li>Mit dem User Experience Team von Netcetera entwickelt</li>
                  <li>
                    Übersichtliche Darstellung von komplexen Masken, Aufgaben,
                    Dokumentlisten
                  </li>
                  <li>Diverse einstellbare Stylesheet</li>
                  <li>
                    Sortieren und Filtern in den Listen, auch bei grossen Listen
                  </li>
                  <li>
                    Dynamischer Wechsel zwischen Tab- und Fenster-Darstellung
                  </li>
                  <li>
                    Integration in ihre Software mit iframes als statische oder
                    dynamische URL Aufrufe
                  </li>
                </ul>

                <h3>Universelles Patientendossier</h3>
                <p>
                  Hier haben Sie alle wichtigen Informationen zum Patienten
                  übersichtlich dargestellt und können damit die aktuellsten
                  Befunde und Untersuchungsergebnisse schnell überblicken.
                </p>

                <StaticImage
                  src="../assets/DICOM-Preview_i-engine.png"
                  alt=""
                  placeholder="none"
                  layout="fullWidth"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                <h3 style={{ marginTop: 16 }}>Unsere Apps</h3>
                <ul>
                  <li>Klinik Cockpit</li>
                  <li>Archiv Viewer</li>
                  <li>Doc Center</li>
                  <li>Order Entry</li>
                  <li>Befund</li>
                  <li>Leistungserfassung</li>
                  <li>Zeiterfassung</li>
                  <li>CIRS</li>
                  <li>Weisungen</li>
                  <li>Transporte</li>
                  <li>Todo</li>
                  <li>eVisit</li>
                  <li>Imito</li>
                  <li>Komed Health – PWA (diverse)</li>
                  <li>Weitere in Vorbereitung</li>
                </ul>
                <StaticImage
                  src="../assets/apps.png"
                  alt=""
                  placeholder="none"
                  layout="fullWidth"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
            </div>
            <div className={row}>
              <div className={col12}>
                <h3>Charts</h3>
                <p>
                  Charts können mit Konfiguration im laufenden System über alle
                  Arten von Daten spezifiziert werden. Jeder berechtigte User
                  kann sich darauf diese Charts anzeigen lassen.
                </p>
                <ul>
                  <li>Line (auch mehrere mit Interpolation)</li>
                  <li>Pie/Donut/Gauge</li>
                  <li>Area (auch Stacked Areas)</li>
                  <li>Scatter Plot</li>
                  <li>Histogramm/Bar Chart</li>
                  <li>Progress Bar</li>
                </ul>

                <StaticImage
                  src="../assets/charts.png"
                  alt=""
                  placeholder="none"
                  layout="fullWidth"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default ClinicalDataRepositoryPage;
